
.App {
  display: flex;
  flex-direction: row;
  height: 100%;
}

/* In your App.css or any other CSS file */
.modal-content {
  position: relative;
  background: white;
  border-radius: 8px;
  padding: 1rem;
  max-height: 90vh; /* Ensure the modal doesn't take up the entire height */
  overflow-y: auto; /* Allow scrolling if content overflows */
  z-index: 1000; /* Ensure it's above other content */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure it's above other content */
}

@media (max-width: 768px) {
  .modal-content {
      width: 100%; /* Make the modal take the full width on mobile */
      height: auto; /* Adjust height for better appearance */
      max-width: none; /* Remove max-width */
  }
}
